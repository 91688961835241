export class Countdown {
  constructor(clock, targetDate, callback) {
    this.clock = clock
    this.targetDate = targetDate ? new Date(targetDate) : new Date()
    this.callback = callback
    this.running = false
    this.cycles = 0
  }

  start() {
    this.running = true
    this.run()
  }

  stop(doCallback) {
    this.running = false
    if (doCallback && this.callback)
      this.callback()
  }

  run() {
    if (!this.running) return

    let secs = Math.floor((this.targetDate - new Date()) / 1000)
    let string = ''

    if (secs >= 86400)              string +=       this.calc(secs, 86400,  100000, 'day'   )
    if (secs >= 3600)               string += ' ' + this.calc(secs, 3600,   24,     'hour'  )
    if (secs >= 60 && secs < 7200)  string += ' ' + this.calc(secs, 60,     60,     'minute')
    if (secs >= 0  && secs < 300)   string += ' ' + this.calc(secs, 1,      60,     'second')

    if (secs >= 0) {
      this.clock.innerHTML = `Begins in <strong>${string}</strong>`
      setTimeout(() => { this.run() }, 990)
      this.cycles += 1
    } else {
      this.clock.innerHTML = 'Enjoy the show!'
      this.stop(this.cycles > 0)
    }
  }

  calc(secs, unit, nextUnit, suffix) {
    let val = Math.floor(secs/unit) % nextUnit
    if (val == 0 && secs > 0) return ''
    return `${val} ${suffix}${val == 1 ? '' : 's'}`
  }
}
