let interval

const checkStream = function(player, url) {
  if (!interval) {
    interval = setInterval(() => checkStream(player, url), 10000)
  }

  fetch(url)
    .then(response => response.text())
    .then(text => {
      if (text.includes('#EXT-X-STREAM-INF')) {
        clearInterval(interval)
        let loadStream = () => player.load([{ file: url }]).play()

        if (player.getState()) {
          loadStream()
        } else {
          player.on('ready', loadStream)
        }
      }
    })
}

document.addEventListener('DOMContentLoaded', function() {
  let settingsMeta = document.querySelector('meta[name="settings"]')
  if (!settingsMeta) return

  let player = jwplayer('player').setup(JSON.parse(settingsMeta.getAttribute('content')))

  let streamMeta = document.querySelector('meta[name="stream"]')
  if (streamMeta) {
    checkStream(player, streamMeta.getAttribute('content'))
  }
})
