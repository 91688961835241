import './player'
import { Countdown } from './countdown'

document.addEventListener('DOMContentLoaded', function() {
  let clock = document.getElementById('countdown_clock')
  if (!clock) return

  let player = jwplayer('player')
  let countdown = new Countdown(clock, clock.getAttribute('data-event-time'), () => player.play())
  player.on('ready', () => countdown.start())
})
